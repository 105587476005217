export const BASE_URLS = {
    // domainUrl: 'http://localhost:9091',
    domainUrl: 'http://51.21.3.29',
    authentication: 'https://example.com/api/authenticate',
    user: 'https://example.com/api/user',
    // Add more URLs as needed
};

export const API_URLS = {
    authentication: '/authenticate',
    stocklist: '/api/stock/stocks',
    addStock: '/api/stock/addStock',
    DAILY_STOCK_DATA_UPLOAD: '/api/excel/dailyStockupload',
    COMPANY_DATA_UPLOAD: '/api/excel/companydataupload',
    user: 'https://example.com/api/user',
    STOCK_DETAILS: '/api/stock/stockById/',
    STOCK_IN_DETAILS: '/api/stock/stockStatementsById/',
    targetupdate: '/api/stock/stockUpdateTargetById/'
    // Add more URLs as needed
};


// git config user.email lwb3hkcg8hepidt13cvsnwwl3b7lts@bots.bitbucket.org

// ATCTT3xFfGN0HnNzew53kjrH4YijsIm21IbGnhkR75gYEnGSfo6gCg1kiSIq3P3pahjXM6iC38KpVbSO5OTN9hW2gBSkOekuldkgV3Iz4gGsPR2prTJs6vMKiIHV7HCjS3kdGTJxJLF-WJjzxvT4Jq8n0Nx17VZpvF2t4wKzRLhn80sqo0K26dk=A39488B9

// git clone https://x-token-auth:ATCTT3xFfGN0HnNzew53kjrH4YijsIm21IbGnhkR75gYEnGSfo6gCg1kiSIq3P3pahjXM6iC38KpVbSO5OTN9hW2gBSkOekuldkgV3Iz4gGsPR2prTJs6vMKiIHV7HCjS3kdGTJxJLF-WJjzxvT4Jq8n0Nx17VZpvF2t4wKzRLhn80sqo0K26dk=A39488B9@bitbucket.org/suhag007/frontend_angular.git
