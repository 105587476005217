import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URLS, API_URLS } from '../config/baseurl';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, map, switchMap, isEmpty } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  uploadData: any;

  constructor(public httpClient: HttpClient) {}

  callRestful(type: string, url: string, options?: { params?: {}; body?: {} }) {
    const httpHeaders = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        // .set('zone', this.getTimeZone())
        // .set('timezoneId', this.getTimeZoneArea())
        .set('Authorization', 'Bearer '+this.getToken())
    };
    switch (type) {
      case 'GET':
        return this.httpClient.get<any>(url, httpHeaders).pipe(
          map(res => res),
          catchError(error => this.handleError(error, type, url))
        );
      case 'POST':
        return this.httpClient.post<any>(url, options.body, httpHeaders).pipe(
          map(res => res),
          catchError(error => this.handleError(error, type, url))
        );
      case 'PUT':
        return this.httpClient.put<any>(url, options.body, httpHeaders);
      case 'DELETE':
        return this.httpClient.delete<any>(url, httpHeaders);
      default:
        return null;
    }
  }

  getToken(): string {
    // const token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI0MjgxZjgxYTgxMDY0ZTkzOTNjY2I0YTEzMWFhNWQ3YSIsImV4cCI6MTUzNzM0MDAxNH0.H3Xgb_OztPd35ce7OC73BK3a0O7bfpmBeUNEOC5CQD66QYoConfbEz4KKCcvNo2HXtxaLuzOrgPc1jDxjh5Frw';
    // const token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5MTc5ODJiNjhlZDA0ODU5OThlMzM1MmE0ZjVhNmMyYyIsImV4cCI6MTUzNzM1NTM5MH0.nqpCAvlhv1WBruK4889SCvFAfgTiNautc-xmQaa-bfmXlIeKeEQQmROXbHcV5UBedvIc0lw7sJNQC1FAio6dLg';
    // return token;
    // return this.getCookie('token');
    if (this.getCookie('token')) {
      return this.getCookie('token');
    } else {
      return '';
    }
  }

  callRestfulWithFormData(method, url: string, formDataArray: Array<any>, stock: any): any {
    const fd = new FormData();
    // stockname

    formDataArray.forEach(element => {
      console.log(element);
      fd.append('file', element);
    });
    if(url.indexOf(API_URLS.DAILY_STOCK_DATA_UPLOAD) > -1) {
      fd.append('stockname', stock.stockname);
    }
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.setRequestHeader('Authorization', 'Bearer '+this.getToken());
    xhr.send(fd);
    return xhr;
  }

  public getCookie(name: string) {
    return localStorage.getItem(name);
  }

  public removeCookie(name: string) {
    return localStorage.removeItem(name);
  }

  private handleError(error: Response | any, type ?: string, url ?: string, isNotError?: boolean ) {
    console.log('error got from api', error);
    let errMsg: string;
    // TODO error message on screen
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return Observable.throw(errMsg);
  }


  login(username: string, password: string, userid: any): Observable<any> {
    // Replace 'your-auth-api-url' with your actual authentication API endpoint
    let url = BASE_URLS.domainUrl+API_URLS.authentication;
    return this.httpClient.post(url, { username, password, userid });
  }

  stockById(id: string) {
    let url = BASE_URLS.domainUrl+API_URLS.STOCK_DETAILS+id;
    return this.callRestful('GET', url);
  }

  postDailyFile(fileToUpload: File, stock: any): any {
    this.uploadData = [];
    this.uploadData.push(fileToUpload);
    let url = BASE_URLS.domainUrl+API_URLS.DAILY_STOCK_DATA_UPLOAD;
    return this.callRestfulWithFormData('POST', url, this.uploadData, stock);
  }

  postCompanyFile(fileToUpload: File, stock: any): any {
    this.uploadData = [];
    this.uploadData.push(fileToUpload);
    let url = BASE_URLS.domainUrl+API_URLS.COMPANY_DATA_UPLOAD;
    return this.callRestfulWithFormData('POST', url, this.uploadData, stock);
  }

  fetchStockList(): Observable<any> {
    let url = BASE_URLS.domainUrl+API_URLS.stocklist;
    return this.callRestful('GET', url);
  }

  addStockList(stock: any): Observable<any> {
    let body = {
      body: stock
    };
    let url = BASE_URLS.domainUrl+API_URLS.addStock;
    return this.callRestful('POST', url, body);
  }

  deepClone(obj) {
    // Handle non-object types and null
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    // Handle arrays
    if (Array.isArray(obj)) {
        const clonedArray = [];
        obj.forEach((item, index) => {
            clonedArray[index] = this.deepClone(item);
        });
        return clonedArray;
    }

    // Handle objects
    const clonedObj = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            clonedObj[key] = this.deepClone(obj[key]);
        }
    }
    return clonedObj;
}




}
